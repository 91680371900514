export const createSimpleCard = (project) => {
    return (dispatch, getState, { getFirebase, getFirestore }) => {
        const firestore = getFirestore();
        const profile = getState().firebase.profile;
        const authorId = getState().firebase.auth.uid;
        firestore.collection('basic').add({
            ...project,
            authorFirstName: profile.userName,
            authorId: authorId,
            createdAt: new Date()
        }).then(() => {
            dispatch({ type: 'CREATE_SIMPLE_CARD', project });
        }).catch((err) => {
            dispatch({ type: 'CREATE_SIMPLE_CARD_ERROR', err});
        })
    }
}